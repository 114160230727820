.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  color: #ffffff;
  min-height: 100vh;
}

.container {
  background-color: #000000;
  background-image: url("../../../assets/stars.png");
  border-radius: 8px;
  padding: 30px;
  max-width: 1000px;
  width: 100%;
  background-size: cover; /* Измените на 'contain', если хотите, чтобы изображение масштабировалось без обрезки */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Предотвращение повторения изображения */
}

.logoCont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 2vh;

  img {
    width: 10vw;
  }

  .title {
    font-size: 3rem;
  }
}
.cardText {
  text-indent: 20px; /* Красная строка */
  font-size: 16px;
  line-height: 1.6;
  text-align: justify;
  margin: 15px;
  color: #ffffff;
}
.cardForm {
  padding: 5px;
  margin: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(159,59,233);
  border-radius: 10px;
  background-color: rgb(159,59,233, 0.1);
  box-shadow: 0px 0px 10px rgba(91, 91, 91, 0.4);
  
}

.form {
  display: flex;
  flex-direction: column;
}

.position {
  display: flex;
  flex-direction: column;

  .positionTitle {
    width: 50%;
    text-align: left;
  }

  .positionInfo {
    display: flex;

    label {
      display: flex;
      align-items: center;
      margin-right: 5%;
      font-size: 1em;
      cursor: pointer;

      input[type="radio"] {
        appearance: none;
        width: 3%;
        height: 3%;
        border: 7px solid #444;
        border-radius: 50%;
        margin-top: 15px;
        margin-right: 10px;
        transition: background-color 0.3s, border-color 0.3s;
        cursor: pointer;

        &:checked {
          background-color: #444;
          border-color: #ffffff;
        }
      }

      &:hover input[type="radio"] {
        border-color: #979797;
      }
    }
  }
}

input,
select {
  background-color: #ffffff2c;
  border: 1px solid rgb(159,59,233);
  color: #ffffff;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
  width: 100%; /* Ensure full width by default */
  -webkit-appearance: none; /* Remove default appearance in Safari */
  -moz-appearance: none; /* Remove default appearance in Firefox */
  appearance: none; /* Remove default appearance */

}::placeholder {
  color: #ffffff4f;
}

button {
  width: 25%;
  background-color: rgb(159,59,233);
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-size: medium;
  cursor: pointer;
  padding: 10px;
  text-align: center;
}

button:hover {
  background-color: rgb(159,59,233);
  color: #ffffff;
}

.driver {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.driverForm,
.carForm {
  padding: 5px;
  margin: 5px;
}

.cardInfo,
.carInfo,
.driverInfo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 5px;
  
  div {
    text-align: left;
    width: 27%;
    margin: auto;
  }
  
  input {
    width: 92%;
  }
  
  select {
    width: 100%;
  }
}

.cardTitle,
.positionTitle,
.driverTitle,
.carInfoTitle {
  margin-bottom: 10px;
  font-size: 2em;
  text-indent: 20px;
}

.agreement {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 1em;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .policy {
    margin-left: 1vw;
    color: #122da1;
    cursor: pointer;
  }

  input[type="checkbox"] {
    appearance: none;
    width: 15px;
    height: 15px;
    border: 2px solid #ffffff;
    border-radius: 5px;
    margin-right: 10px;
    transition: background-color 0.3s, border-color 0.3s;
    cursor: pointer;

    &:checked {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url("../../../assets/galochka.png");
    }
  }

  a {
    color: #4fa3e3;
    text-decoration: none;
    margin-left: 5px;
  }

  a:hover {
    text-decoration: underline;
  }
}

.birthday {
  input {
    height: 1.6vh;
  }
}

/* Адаптивные стили */
@media (max-width: 1200px) {
  .container {
    max-width: 800px;
  }

  .position,
  .carInfo div,
  .driverInfo div {
    width: 45%;
    margin-right: 5%;
  }

  .positionTitle {
    width: 100%;
  }

  .cardInfo div {
    flex-direction: column;
    width: 100%;
  }
  
  .cardTitle,
  .positionTitle,
  .driverTitle,
  .carInfoTitle {
    font-size: 1em;
    font-weight: bold;
  }

  .positionInfo {
    flex-direction: column;

    label {
      input[type="radio"] {
        width: 2%;
        height: 2%;
      }

      &:hover input[type="radio"] {
        border-color: #ffffff;
      }
    }
  }

  .logoCont {
    img {
      width: 50%;
    }
  }

  select {
    width: 100%; /* Ensure full width in all responsive states */
  }

  input {
    width: 100%; /* Ensure full width in all responsive states */
  }

  button {
    width: 50%;
  }

  .agreement {
    font-size: 0.9em;

    input[type="checkbox"] {
      width: 15px;
      height: 15px;
    }
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 600px;
  }
  .cardInfo div {
    flex-direction: column;
    width: 100%;
  }
  .position,
  .carInfo div,
  .driverInfo div {
    width: 100%;
    margin-right: 0;
  }

  .positionTitle {
    width: 100%;
  }

  .cardTitle,
  .positionTitle,
  .driverTitle,
  .carInfoTitle {
    font-size: 1em;
    font-weight: bold;
  }

  .logoCont {
    img {
      width: 50%;
    }
  }

  select {
    width: 100%; /* Ensure full width in all responsive states */
  }

  input {
    width: 100%; /* Ensure full width in all responsive states */
  }

  button {
    width: 25%;
  }

  .agreement {
    font-size: 0.8em;

    input[type="checkbox"] {
      width: 13px;
      height: 13px;
    }
  }
}

@media (max-width: 480px) {
  .container {
    max-width: 100%;
    padding: 10px;
  }
  .cardInfo div {
    flex-direction: column;
    width: 100%;
    margin-right: 0px;
  }
  .position,
  .carInfo div,
  .driverInfo div {
    width: 100%;
    margin-right: 0px;
  }

  .positionTitle {
    width: 100%;
  }

  .cardTitle,
  .positionTitle,
  .driverTitle,
  .carInfoTitle {
    font-size: 1em;
    font-weight: bold;
  }

  .positionInfo {
    flex-direction: column;

    label {
      input[type="radio"] {
        width: 2%;
        height: 2%;
      }

      &:hover input[type="radio"] {
        border-color: #ffffff;
      }
    }
  }

  .logoCont {
    img {
      width: 50%;
    }
  }

  select {
    width: 100%; /* Ensure full width in all responsive states */
  }

  input {
    width: 100%; /* Ensure full width in all responsive states */
  }

  button {
    width: 100%;
    font-size: 4vw !important;
  }

  .agreement {
    font-size: 1em;

    input[type="checkbox"] {
      width: 15px;
      height: 15px;
    }
  }
}
