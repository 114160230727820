/* Wrapper for the modal */
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  overflow: hidden; /* Prevent scrolling the background */
}

/* Container for modal content */
.container {
  text-align: center;
  background-color: #171717;
  width: 50vw;
  max-height: 80vh; /* Limit height to allow scrolling */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  overflow-y: auto; /* Enable vertical scrolling */
  position: relative; /* For positioning the close button */
}

/* Logo styling */
.logo {
  width: 10vw;
  max-width: 100px;
}

/* Title styling */
.title {
  font-size: 2vw;
  color: #fff;
}

/* Paragraph styling */
.text {
  text-align: start;
  font-size: 1vw;
  color: #fff;
  padding: 10px;
  margin: 0;
}

/* Close button specific styling */
.close-button {
  width: 25%; /* Adjust width as needed */
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  color: #000000;
  font-size: 1vw;
  cursor: pointer;
  padding: 10px;
  text-align: center;

  &:hover {
    background-color: #333;
    color: #ffffff;
  }
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .container {
    width: 70vw;
  }

  .logo {
    width: 15vw;
  }

  .title {
    font-size: 2.5vw;
  }

  .text {
    font-size: 1.2vw;
  }

  .close-button {
    font-size: 1.2vw;
  }
}

@media (max-width: 768px) {
  .container {
    width: 90vw;
  }

  .logo {
    width: 20vw;
  }

  .title {
    font-size: 3vw;
  }

  .text {
    font-size: 2vw;
  }

  .close-button {
    font-size: 3vw;
  }
}

@media (max-width: 480px) {
  .container {
    width: 90vw;
    max-height: 80vh;
  }

  .logo {
    width: 25vw;
  }

  .title {
    font-size: 4vw;
  }

  .text {
    font-size: 2vw;
  }

  .close-button {
    font-size: 2.5vw;
  }
}
