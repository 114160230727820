.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  overflow: hidden;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40vw;
  height: 40vh;
  background-color: #171717;
  padding: 3vw;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 11;
}

.storeBtns {
  display: flex;
  flex-wrap: wrap;
  gap: 1vw;
}

.appButton {
  cursor: pointer;
  width: 15vw;
  transition: transform 0.3s;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.05);
  }
}

h1 {
  position: absolute;
  top: 2vh;
  color: #ffffff;
  text-align: center; /* Центрирование заголовка */
}

.info {
  text-align: center;
  color: #ffffff;
  font-size: 1.2vw;
  padding: 2vw;
}

.closeBtn {
  background-color: #ffffff;
  color: #171717;
  border: 1px solid white;
  border-radius: 0.5vw;
  text-decoration: none;
  font-size: 1.5vw;
  transition: background-color 0.3s;
  width: 15vw;
  height: 6vh;
  transition: transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;

  &:hover {
    background-color: #6f6f6f;
    transform: scale(1.05);
  }
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
  .modal {
    width: 100vw !important;
    height: 100vh !important;
    padding: 5vw !important;
    border-radius: 0 !important; /* Убираем закругленные углы */
  }

  .storeBtns {
    flex-direction: column !important;
    gap: 3vw !important; /* Увеличиваем отступы между кнопками */
  }

  .appButton,
  .closeBtn {
    width: 60vw !important;
    font-size: 4vw !important;
  }

  .closeBtn {
    position: absolute;
    bottom: 10vh;
    height: 7vh !important;
    border-radius: 2vw;
  }

  .info {
    border: 1px solid white;
    border-radius: 2vw;
    text-align: start;
    font-size: 5vw !important;
    padding: 4vw !important;
    margin-bottom: 5vh;
  }

  h1 {
    font-size: 6vw !important;
    top: 5vh !important; /* Добавляем отступ сверху */
  }
}

/* Адаптивность для планшетов */
@media (max-width: 1024px) {
  .modal {
    width: 60vw;
    height: 50vh;
    padding: 4vw;
  }

  .storeBtns {
    flex-direction: row;
    gap: 2vw;
  }

  .appButton,
  .closeBtn {
    width: 30vw;
    font-size: 2vw;
    height: auto;
  }

  .info {
    font-size: 2vw;
    padding: 3vw;
  }

  h1 {
    font-size: 3vw;
  }
}
