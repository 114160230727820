.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120vh;
  background-color: #000000;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #171717;
  width: 50vw;
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-image: url("../../../assets/stars.png"); /* Добавьте здесь путь к вашему изображению */
  background-size: cover; /* Измените на 'contain', если хотите, чтобы изображение масштабировалось без обрезки */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Предотвращение повторения изображения */
}

.titleCont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 2vh;
  font-size: 1.5vw;
  color: #ffffff;
  row-gap: 1vh;

  .title {
    font-size: 3rem;
  }

  .logo {
    width: 10vw;
    margin-top: 2vh;
  }
}

.registerBtn {
  display: inline-block;
  text-align: center; /* Выравнивание текста по центру */
  background-color: #ffffff;
  color: #171717;
  border: 1px solid #ffffff;
  border-radius: 1vw;
  text-decoration: none;
  font-size: 2vw;
  width: 20vw;
  height: 10vh;
  transition: transform 0.3s;
  margin-top: 2vh;

  &:hover {
    transform: scale(1.05);
  }
}

.appButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5vh;
}

.appButton2,
.appButton {
  cursor: pointer;
  width: 20vw;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
}

.appButton2 {
  margin-top: 2.2vh;
}

.callBtn {
  position: fixed;
  bottom: 2vh;
  right: 5vw;
  cursor: pointer;
  width: 5vw !important;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
}
.whBtn {
  position: fixed;
  bottom: 15vh;
  right: 5vw;
  cursor: pointer;
  width: 5vw !important;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 768px) {
  .container {
    width: 100vw !important;
    height: 120vh !important;
    padding: 10px !important;
  }

  .logo {
    width: 50vw !important;
  }

  .title {
    font-size: 5vw !important;
  }

  .registerBtn {
    width: 80vw !important;
    font-size: 6vw !important;
    height: 10vh !important;
    border-radius: 2vh;
  }

  .appButtons {
    margin-top: 4vh !important;
  }

  .appButton2,
  .appButton {
    width: 80vw !important;
    font-size: 5vw !important;
  }

  .appButton2 {
    margin-top: 1vh !important;
  }

  .contactsBtn {
    display: none;
  }
}

/* Адаптивные стили для планшетов */
@media (max-width: 1024px) {
  .container {
    width: 100vw;
    padding: 15px;
  }

  .logo {
    width: 30vw;
  }

  .title {
    font-size: 3vw;
  }

  .registerBtn {
    width: 40vw;
    font-size: 4vw;
    height: 8vh;
  }

  .appButtons {
    margin-top: 3vh;
  }

  .appButton2,
  .appButton {
    width: 40vw;
    font-size: 3vw;
  }

  .appButton2 {
    margin-top: 1vh;
  }

  .contactsBtn {
    display: none;
  }
}
